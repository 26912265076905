import styled from "styled-components";

const ListItemStyles = styled.div`
  border-bottom: ${({ border }) => border || "1px solid #F0F0F0"};
  padding: 12px 20px;
  display: flex;
  -moz-box-align: center;
  align-items: center;
  background: ${({ background }) => background || "transparent"};

  .isVerified {
    width: 20%;
    padding: 16px 0;
    font-size: 14px;
    text-align: center;
    svg {
      width: 35px;
      height: 35px;
    }
  }

  .checkboxCol {
    margin-right: 24px;
    width: 18px;
  }

  .list {
    display: flex;
    align-items: center;
    width: 100%;
    cursor: pointer;
  }

  .nameCol {
    display: flex;
    align-items: center;
    width: 30%;
    padding: 12px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #5f5f5f;
    text-transform: capitalize;
    position: relative;

    .employeeAvatar {
      margin-left: 10px;
      min-width: 24px;
      min-height: 24px;
      border-radius: 12px;
      background: #1568bf;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 10px;
      font-size: 12px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      text-transform: uppercase;
    }
  }

  .roles {
    width: 20%;
    padding: 16px 0;
    margin-left: 1%;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #777777;
  }

  .company {
    width: 20%;
    padding: 16px 0;
    margin-left: 1%;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #777777;
  }

  .email {
    width: 22%;
    padding: 16px 0;
    margin-left: 1%;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #777777;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .actionsCol {
    width: 4%;
    display: flex;
    -moz-box-align: center;
    align-items: center;
    -moz-box-pack: center;
    justify-content: center;
    background: none;
    border: medium none;
    outline: currentcolor none medium;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: rgb(143, 143, 143);
    cursor: pointer;
    position: relative;
    margin: 0px;
    padding: 0px;

    .tooltip {
      width: 180px;
      /* height: 107px; */
      background: #f7f7f7;
      border: 1px solid #1568bf !important;
      padding: 0;
      border-radius: 4px;
      padding: 0;
    }
  }

  .tooltipLink {
    text-decoration: none;
    width: 100%;
    height: 34px;
  }

  .tooltipContentWrapper {
    width: 100%;
    height: 100%;
    padding: 0;
  }

  .button {
    width: 100%;
    height: 34px;
    background: transparent;
    border: none;
    outline: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0 16px;
    font-size: 11px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #8f8f8f;
    text-transform: capitalize;

    &:hover {
      background: #1568bf;
      color: #fff;
    }
  }

  .list-disabled-tooltip {
    color: black !important;
    text-align: center;
  }

  .__react_component_tooltip {
    background: #f7f7f7 !important;
    opacity: 1 !important;
    padding: 0;
    width: 180px;
    border-radius: 4px;
    border: 1px solid rgb(21, 104, 191) !important;
  }

  .__react_component_tooltip.show {
    opacity: 1;
    visibility: visible;
  }

  .__react_component_tooltip.type-dark.border {
    border: 1px none blue;
  }

  .__react_component_tooltip.place-bottom:after {
    top: -7px !important;
  }

  .__react_component_tooltip.place-left::after {
    border-left: 8px solid #f7f7f7 !important;
  }

  .__react_component_tooltip.place-right::after {
    border-right: 8px solid #f7f7f7 !important;
  }

  .__react_component_tooltip.place-top::after {
    border-top: 8px solid #f7f7f7 !important;
  }

  .__react_component_tooltip.place-bottom::after {
    border-bottom: 8px solid #f7f7f7 !important;
  }

  .__react_component_tooltip.place-bottom:before {
    border-left: 9px solid transparent;
    border-right: 9px solid transparent;
    margin-left: -10px;
  }

  .__react_component_tooltip.type-dark.border.place-bottom:before {
    border-bottom: 8px solid #1568bf !important;
  }
`;

export default ListItemStyles;
