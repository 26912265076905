import { createStore, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";

import { rootReducer } from "../reducers/root.reducer";

const initStore = () => {
  const middlewares = [thunk];
  const enhancer =
    process.env.NODE_ENV === "production"
      ? applyMiddleware(...middlewares)
      : composeWithDevTools(applyMiddleware(...middlewares));

  return createStore(rootReducer, enhancer);
};

export const useStore = () => {
  return initStore();
};
